<template>
    <div class="paymentMethods">
        <div class="totalAmount">
            <h3>{{$t('orderAmount')}} {{formatPrice(totalAmount.toFixed(2))}}</h3>
            <span>{{$t('chooseMethod')}}</span>
        </div>
        <div class="justify-content-center paymentMethodsList">

            <div v-for = "paymentMethod in paymentMethodsList" v-if="showPaymentMethod(paymentMethod)" :key="paymentMethod.id" no-body class="card">
                <div v-b-toggle = "'accordion-' + paymentMethod.id" class="card-header paymentMethodCardHeader firstCard">
                    <div class="radio">
                        <input type="radio" name="paymentMethod" v-bind:id="paymentMethod.id" v-bind:checked="paymentMethod.favorite"/>
                        <label for="paymentMethod.id" class="paymentMethodName">{{paymentMethod.methodTitle}}</label>
                        <span class="checkround"></span>
                    </div>
                </div>
                <b-collapse v-bind:id="'accordion-' + paymentMethod.id" v-bind:visible="paymentMethod.favorite" accordion="acc">
                    <b-card>
                        <component v-bind:is="paymentMethod.fileName.replace(/\s/g, '')" :totalAmount="totalAmount" :paymentMethod="paymentMethod" class="row d-flex justify-content-center paymentMethod"></component>
                    </b-card>
                </b-collapse>

            </div>

            <div id="paymentLoyaltyCheck">
                <b-form-checkbox
                        v-if="(totalAmount > currentLoyaltyPoints && currentLoyaltyPoints != 0) || useLoyalty == 'true'"
                        id="useLoyalty"
                        v-model="useLoyalty"
                        value="true"
                        unchecked-value="false">
                    {{ $t('paymentLoyalty', { bonus: formatPrice(currentLoyaltyPoints.toFixed(2)) }) }}
                </b-form-checkbox>
            </div>

        </div>

        <div class="paymentMethodsFooter">
            <Alert v-if="alertContent!=''" :alertContent="alertContent" :alertVariant="'danger'"></Alert>
            <div class="footerActions">
                <button class="btn btn-active backBtn" style="width:100%" @click="handleBack('/')">{{$t('back')}}</button>
            </div>
        </div>
    </div>

</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    import SIPS from './PaymentMethods/Sips.vue'
    import Valinawithdraw from './PaymentMethods/Valinawithdraw.vue'
    import Loyalty from './PaymentMethods/Loyalty.vue'
    var Checkout = require('@/assets/js/Checkout');
    var Utils = require("@/assets/js/Utils");
    var Stats = require("@/assets/js/Stats");
    var MQTT = require("@/assets/js/MqttService");

    export default {
        name: 'PaymentMethods',
        components: {
            SIPS,
            Valinawithdraw,
            Loyalty
        },
        data: function() {
            return{
                paymentMethodsList: [],
                fromRoute: null,
                alertContent : "",
                totalAmount: 0,
                customerBasket: null,
                currentLoyaltyPoints: 0,
                useLoyalty: "false"
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
            })
        },
        created(){
            this.customerBasket = this.$parent.basket;
            this.totalAmount = this.customerBasket.totalAmount;
            this.paymentMethodsList = Checkout.getPaymentMethodsList(this);
            localStorage.setItem("DKC_ticket", JSON.stringify(this.customerBasket));
            localStorage.setItem("DKC_ticketAmount", this.totalAmount);
            if (this.$route.params.error) {
                this.alertContent = this.$t('paymentError')
            }
            localStorage.removeItem("DKC_loyaltyToUse");
            this.$parent.refreshCustomer();
            if(isNotNull(this.$parent.customer) && isNotNull(this.$parent.customer.loyalty)){
                this.currentLoyaltyPoints = this.$parent.customer.loyalty.loyaltyPoint;
            }else{
                this.currentLoyaltyPoints = 0;
            }
        },
        methods: {
            handleBack (fallback) {
                if (!this.fromRoute.name) {
                    this.$router.push(localStorage.getItem("DKC_base") + fallback);
                } else {
                    this.$router.push(localStorage.getItem("DKC_base") + "/basket");
                }
            },
            paymentCanceled(){
                this.alertContent = this.$t('paymentError')
            },
            formatPrice(price) {
                return Utils.formatPrice(price);
            },
            showPaymentMethod(paymentMethod){
                if(paymentMethod.fileName == "Loyalty" && (this.totalAmount >= this.currentLoyaltyPoints || this.useLoyalty == "true")){
                    return false;
                }
                return true;
            },
            methodPaymentChoosen(callback){
                this.$parent.showLoader = true;
                var paymentMethodChoosen = this.paymentMethodChoosen;
                if(isNotNull(paymentMethodChoosen)){
                    var parameters = {};
                    parameters[paymentMethodChoosen] = 1;
                    Stats.setStats(parameters);

                    MQTT.publishMessage("SELECT_PAYMENT", '{"method": "' + paymentMethodChoosen + '"}', callback);
                }
            }
        },
        watch:{
            useLoyalty: function () {
                if(this.useLoyalty == "true"){
                    localStorage.setItem("DKC_loyaltyToUse", this.currentLoyaltyPoints);
                    localStorage.setItem("DKC_ticketAmount", this.totalAmount - this.currentLoyaltyPoints);
                    this.totalAmount = this.totalAmount - this.currentLoyaltyPoints;
                }else{
                    localStorage.removeItem("DKC_loyaltyToUse");
                    localStorage.setItem("DKC_ticketAmount", this.totalAmount);
                    this.totalAmount = this.customerBasket.totalAmount;
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            if(to.name != "Basket"){
                this.methodPaymentChoosen(() => {
                    this.$parent.showLoader = false;
                    next();
                });
            }else{
                next();
            }
        }
    }
</script>