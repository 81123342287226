<template>
    <div id="sips">
        <div v-if="enablePaymentMethod()">
            <p>{{$t('payWithPhone')}}</p>
            <p>{{$t('redirectToSIPS')}}</p>
            <form id="paymentForm" ref="paymentForm" @submit.prevent="validBasket" method="POST" action="https://payment-webinit.simu.sips-atos.com/paymentInit">
                <input type="hidden" name="Data" id="paymentData" :value="paymentData">
                <input type="hidden" name="InterfaceVersion" value="HP_2.16">
                <input type="hidden" name="Seal" id="paymentSeal" :value="paymentSeal">
                <input class="btn btn-active" type="submit" :value="this.$t('goToSIPS')">
            </form>
        </div>
        <div v-else>
            <p>{{$t('methodPaymentNotAvailable')}}</p>
        </div>
    </div>
</template>

<script>
    var sha256 = require("sha256");
    var checkout = require("@/assets/js/Checkout");
    var Utils = require("@/assets/js/Utils");
    export default {
        name: "Sips",
        props : ['totalAmount', 'paymentMethod'],
        data: function() {
            return {
                merchantId : "",
                lang : "en",
                paymentData : "",
                paymentSeal : ""
            }
        },
        created(){
            this.lang = this.$root.$i18n.locale;
            this.merchantId = Utils.getConfig().merchantId;
            this.merchantId = this.merchantId.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            this.merchantId = this.merchantId.replace("'", "");
            this.merchantId = this.merchantId.toUpperCase();
            var urlRedirect = window.location.origin + localStorage.getItem("DKC_base") + "/paymentdone"
            this.paymentData = 'amount='+Math.floor(this.totalAmount*100)+'|paypageData.bypassReceiptPage=Y|fraudData.bypass3DS=ALL|currencyCode=978|merchantId=002001000000001|paymentMeanBrandList=CB,VISA,MASTERCARD|keyVersion=1|transactionReference='+this.getContext()+ "|customerLanguage=" + this.lang+ '|normalReturnUrl=' + urlRedirect;
            this.paymentSeal = sha256(this.paymentData+'002001000000001_KEY1');
        },
        methods : {
            getContext : function() {
                var contextId = this.merchantId+Date.now();
                return contextId;
            },
            validBasket: function() {
                this.$parent.$parent.paymentMethodChoosen = this.$props.paymentMethod.methodName;
                this.$parent.$parent.methodPaymentChoosen(() => {
                    this.$refs.paymentForm.submit();
                });
            },
            enablePaymentMethod() {
                return checkout.enablePaymentMethod(this.$options.name, this);
            }
        },
        watch:{
            totalAmount: function () {
                var urlRedirect = window.location.origin + localStorage.getItem("DKC_base") + "/paymentdone"
                this.paymentData = 'amount='+Math.floor(this.totalAmount*100)+'|paypageData.bypassReceiptPage=Y|fraudData.bypass3DS=ALL|currencyCode=978|merchantId=002001000000001|keyVersion=1|transactionReference='+this.getContext()+ "|customerLanguage=" + this.lang+ '|normalReturnUrl=' + urlRedirect;
                this.paymentSeal = sha256(this.paymentData+'002001000000001_KEY1');
            }
        }
    }
</script>