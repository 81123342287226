<template>
    <div id="valinaWithdraw">
        <div v-if="enablePaymentMethod()">
            <p>{{$t('payInStore')}}</p>
            <button @click="pay" class="btn btn-active" >{{$t('payInStoreTitle')}}</button>
        </div>
        <div v-else>
            <p>{{$t('methodPaymentNotAvailable')}}</p>
        </div>
    </div>
</template>

<script>
var checkout = require("@/assets/js/Checkout");
export default {
    name: "ValinaWithdraw",
    props : ['paymentMethod'],
    data: function() {
        return{
            base : localStorage.getItem('DKC_base')
        }
    },
    methods: {
        enablePaymentMethod() {
            return checkout.enablePaymentMethod(this.$options.name, this);
        },
        pay (){
            this.$parent.$parent.paymentMethodChoosen = this.$props.paymentMethod.methodName;
            this.$router.push(this.base + '/scan?inPayment=true');
        }
    }

}
</script>