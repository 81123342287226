<template>
    <div id="paymentLoyalty">
        <div v-if="enablePaymentMethod()">
            <p>{{ $t('useLoyalty', { bonusUse: formatPrice(totalAmount.toFixed(2)), bonus: formatPrice($parent.$parent.currentLoyaltyPoints.toFixed(2)) }) }}</p>
            <button @click="pay" class="btn btn-active" >{{$t('pay')}}</button>
        </div>
        <div v-else>
            <p>{{$t('methodPaymentNotAvailable')}}</p>
        </div>
    </div>
</template>

<script>
    var checkout = require("@/assets/js/Checkout");
    var Utils = require("@/assets/js/Utils");
    export default {
        name: "Loyalty",
        props : ['totalAmount', 'paymentMethod'],
        data: function() {
            return{
                base : localStorage.getItem('DKC_base')
            }
        },
        methods: {
            enablePaymentMethod() {
                return checkout.enablePaymentMethod(this.$options.name, this);
            },
            formatPrice(price) {
                return Utils.formatPrice(price);
            },
            pay (){
                this.$parent.$parent.paymentMethodChoosen = this.$props.paymentMethod.methodName;
                localStorage.setItem("DKC_loyaltyToUse", this.totalAmount);
                this.$router.push(this.base + '/paymentDone');
            }
        }

    }
</script>